<template>
  <!--<div
    v-if="carregandoPagina"
    class="col-md-12"
    style="text-align: center; padding-top: 255px"
  >
    <pm-ProgressSpinner />
  </div>-->
  <section >
    <!--<section v-if="!carregandoPagina">-->
    <div class="row">
      <div class="col-md-8">
        <h2 class="titulo-links-escolha">Atas</h2>
      </div>
      <div class="col-md-4 tbl-cadastro">
        <pm-Button
          :disabled="!anoSitucao"
          type="button"
          @click="openModal(undefined)"
          label="CADASTRAR ATA"
          class="p-button-success"
          icon="pi pi-external-link"
          title="CADASTRAR NOVA ATA"
        ></pm-Button>
      </div>
    </div>
    <div>

      <!--Buscar atas por filtro-->
      <painelFiltroV2 @buscarAtas="buscarAtas" />
      <br />
    </div>
    <div>
      <pm-DataTable
        :value="Array.from(atasALL)"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome']"
        responsiveLayout="scroll"
      >
        <pm-Column
          field="numero"
          header="N.º DA ATA"
          style="font-size: 14px; vertical-align: middle !important"
        >
        </pm-Column>

        <pm-Column
          field="escola"
          header="ESCOLA"
          sortable
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>
              {{ data.escola_nome }}
            </p>
          </template>
        </pm-Column>

        <pm-Column
          field="segmento"
          header="SEGMENTO"
          sortable
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>
              {{ data.segmento_nome }}
            </p>
          </template>
        </pm-Column>

        <pm-Column
          field="serie"
          header="PERÍODO"
          sortable
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>
              {{ data.serie_nome }}
            </p>
          </template>
        </pm-Column>

        <pm-Column
          field="turma"
          header="TURMA"
          sortable
          style="font-size: 14px; vertical-align: middle !important"
        >
          <template #body="{ data }">
            <p>
              {{ data.turma_nome }}
            </p>
          </template>
        </pm-Column>

        <pm-Column
          field="id"
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important"
        >
          <template #body="{ data }">
            <pm-Button
              type="button"
              @click="perguntaVerAluno(data)"
              icon="pi pi-users"
              label="Ver Alunos"
              title="Ver Alunos"
              class="p-button-warning p-button-sm btn-color table_style"
              style="margin-left: 5px"
            ></pm-Button>
            <pm-Button
              v-if="data.visualizado == 0 || (funcao == 1 || funcao == 2)"
              :disabled="!anoSitucao"
              title="Apagar Ata"
              type="button"
              @click="excluir(data.id)"
              label="Excluir"
              icon="pi pi-times-circle"
              class="p-button-danger p-button-sm btn-color table_style"
              style="margin-left: 5px; margin-top: 4px"
            ></pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>
    </div>
  </section>

  <pm-Dialog
    :header="labelModal"
    v-model:visible="showModal"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
  >
    <p class="mb-3" style="font-size: 14px; color: #eb3b3b;"><b>ATENÇÃO: </b>Os alunos inseridos na turma após a criação da Ata não serão incluídos na Ata.</p>
    <painelFiltrarTurma2 @getTurma="getTurma" />
    <div class="field col-12 md:col-12" style="padding-top: 16px">
      <div class="row">
        <div class="col-md-12" v-if="infoAtas.turma_id">
          <pm-Button
            @click="cadastrar"
            style="float: right"
            label="Salvar"
            icon="pi pi-check"
          />
        </div>
      </div>
    </div>
  </pm-Dialog>

  <pm-Dialog
    header="Confirmação de abertura de ata"
    v-model:visible="showpergunta"
    :style="{ width: '50vw' }"
    :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
  >

    <p><strong>Recomendamos clicar neste botão somente após lançar todas as presenças e notas dos alunos.</strong></p>

    <br><br><br>
    <p style="color: #eb3b3b;">*Após prosseguir com essa ação, <strong>a ata não poderá ser excluída!</strong></p>
    <div class="field col-12 md:col-12" style="padding-top: 16px">
      <div class="row">
        <div class="col-md-12" >

          <pm-Button
            @click="this.showpergunta = false"
            style="float: right;margin-right:5px;"
            label="Não"
            icon="pi pi-close"
            class="p-button-danger"
          />

          <pm-Button
            @click="verAlunos()"
            style="float: right;margin-right: 5px;"
            label="Sim"
            icon="pi pi-check"
            class="p-button-success"
          />
        </div>
      </div>
    </div>
  </pm-Dialog>
</template>

<script>
import { defineComponent } from "vue";

import axios from "axios";
import painelFiltrarTurma2 from "./filtros/painelFiltrarTurma2.vue";
import painelFiltroV2 from "./filtros/painelFiltroV2.vue";
import { Atas } from "@/pages/admin/pages/relatorios/services/ata.service";
import { LoginInfo } from "@/class/login";

export default defineComponent({
  components: {
    painelFiltrarTurma2,
    painelFiltroV2,
  },
  data() {
    return {
      filters: "",
      showModal: false,
      showModalRelatorio: false,
      carregandoPagina: false,
      atasALL: [],
      infoAtas: {
        ano: "2023",
        situacao: 1,
        turma_id: 0,
        possui_dependecia: false,
      },
      buscar: {
        ano: "2023",
        numero: "",
        turma_nome: "",
        segmento_nome: "",
        serie_nome: "",
        escola_nome: "",
      },
      labelModal: "Cadastrar Ata",
      showButton: {
        cadastrar: false,
        editar: false,
      },
      relatorio: [],
      showpergunta:false,
      dados:[],
      anoSitucao:0
    };
  },
  computed: {
    pages() {
      if (this.perPage && this.perPage !== "0") {
        return Math.ceil(this.listaAlunosTransferidos.total / this.perPage);
      } else {
        return this.listaAlunosTransferidos.total;
      }
    },
  },
  methods: {
    perguntaVerAluno(info){
      this.dados = info
      if(info.visualizado == 1){
        this.verAlunos()
      }else{
        this.showpergunta = true;
      }
    },

    verAlunos() {
      this.$router.push({
          name: 'ataAlunos',
          params: {
              turma_id: this.dados.turma_id,
              ata_id: this.dados.id,
              ata_numero: this.dados.numero,
              escola_nome: this.dados.escola_nome,
              turma_nome: this.dados.turma_nome,
              segmento_nome: this.dados.segmento_nome,
              serie_nome: this.dados.serie_nome,

          },
      });

    },
    openModal() {
        //cadastrar
        this.labelModal = "Cadastrar Ata";
        this.infoAtas.numero = "";
        this.infoAtas.situacao = "";
        this.infoAtas.turma_id = "";
        this.infoAtas.possui_dependecia = "";
        this.showModal = true;
    },
    getTurma(info) {
      this.infoAtas.turma_id = info.turma_id;
      this.infoAtas.ano = info.ano;
    },
    cadastrar() {
      this.carregandoPagina = true;
      Atas.store(this.infoAtas)
        .then((resp) => {
          this.$vaToast.init({
            message: "Ata cadastrada com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            color: "success",
            fullWidth: false,
          });
        })
        .catch((err) => {
          const errorMessage = err.response.data.erro;
          this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
            });
        })
        .finally(() => {
          this.carregandoPagina = false;
          this.showModal = false;
          this.buscarAtas({}, 0, 1);
        });
    },
    excluir(id) {
      if (window.confirm("Você realmente deseja excluir a ata selecionada?")) {
        this.carregandoPagina = true;
        Atas.excluir(id)
          .then((resp) => {})
          .catch((err) => {
            const errorMessage = err.response.data.erro;
            this.$vaToast.init({
              message: errorMessage,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 2500,
              fullWidth: false,
            });
          })
          .finally(() => {
            this.buscarAtas({}, 0, 1);
          });
      }
    },

    buscarAtas(data, d = 0, n = 0) {
      this.carregandoPagina = true;
      this.atasALL = data;
  
      if (n === 1) {
        if (d === 1) {
          axios
            .get(
              "/atas?ano=" +
                data.ano +
                "&numero=" +
                data.numero +
                "&turma_nome=" +
                data.turma_nome +
                "&segmento_nome=" +
                data.segmento_nome +
                "&serie_nome=" +
                data.serie_nome +
                "&escola_nome=" +
                data.escola_nome +
                ""
            )
            .then((resp) => {
              this.atasALL = resp.data;
            })
            .catch((err) => {})
            .finally(() => {
              this.carregandoPagina = false;
            });
        } else {
          axios
            .get(
              "/atas?ano=" +
                this.buscar.ano //+
                // "&numero=" +
                // this.buscar.numero +
                // "&turma_nome=" +
                // this.buscar.turma_nome +
                // "&segmento_nome=" +
                // this.buscar.segmento_nome +
                // "&serie_nome=" +
                // this.buscar.serie_nome +
                // "&escola_nome=" +
                // this.buscar.escola_nome +
                // ""
            )
            .then((resp) => {
              this.atasALL = resp.data;
            })
            .catch((err) => {})
            .finally(() => {
              this.carregandoPagina = false;
            });
        }
      }
      else {
        this.carregandoPagina = false;
      }

      //else {
      //   this.atasALL = data;
      //   this.carregandoPagina = false;

      //   axios
      //       .get(
      //         "/atas?ano=" +
      //           this.buscar.ano +
      //           "&numero=" +
      //           this.buscar.numero +
      //           "&turma_nome=" +
      //           this.buscar.turma_nome +
      //           "&segmento_nome=" +
      //           this.buscar.segmento_nome +
      //           "&serie_nome=" +
      //           this.buscar.serie_nome +
      //           "&escola_nome=" +
      //           this.buscar.escola_nome +
      //           ""
      //       )
      //       .then((resp) => {
      //         this.atasALL = resp.data;
      //         console.log(this.atasALL);
      //       })
      //       .catch((err) => {})
      //       .finally(() => {
      //         this.carregandoPagina = false;
      //       });
      // }
    },
  },
  async beforeMount() {
    this.infoAtas.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    this.buscar.ano = parseInt(sessionStorage.getItem("anoSelecionado"));

    this.anoSitucao = parseInt(sessionStorage.getItem("anoSitucao"));
    const token = sessionStorage.getItem("token");
    const whoiam = await LoginInfo.WhoIam(token);
    const diretor = whoiam.data.diretor

    this.funcao = whoiam.data.funcao;
    if(this.funcao == 1 || this.funcao == 2 || this.funcao == 7 || this.funcao == 4 || this.funcao == 3 || diretor === 1){
      this.buscarAtas({}, 0, 1);
    }
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}

.position {
  // writing-mode: vertical-rl;
  // text-orientation: mixed;
  // float: left;
}
</style>
