<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="col-md-12" style="margin-top:-15px;padding: 14px;" >
      <atas/>

    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';

import reportficha from "./components/fichaindividual/tblFicha.vue"
import painelFiltro from "./components/filtros/painelFiltro.vue"
import modeloPai from "./components/fichaindividual/modeloPai.vue"
import atas from "./components/atas.vue"

export default defineComponent ({
  components:{
    reportficha,painelFiltro,modeloPai,atas
  },
  data(){
    return{
      base_url: axios.defaults.baseURL.replace('/api/v1',''),
      home: {icon: 'pi pi-home', to: '/relatorios/atas'},
      items_bread: [
        {label: 'Atas'},
      ],

      visivel:false,
      filtro:[
        {
          nome:"Nome",
          check:true,
          _id:0,
        },
        {
          nome:"Filiação",
          check:true,
          _id:1,
        },
      ],
      dados:[]
    }
  },
  async beforeMount() {

  },
  methods: {

  }
});
</script>

<style>

</style>
